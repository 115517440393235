import moment from 'moment';

export function amountsParser(str: string): number {
  str = str.replace(',', '.');
  const parts = str.split('.');
  if (parts.length === 1) {
    return Number(str);
  } else {
    const newStr = parts.slice(0, -1).join('') + '.' + parts.slice(-1);

    return Number(newStr);
  }
}

const formats = [
  'DD MM YYYY',
  'D M YYYY',
  'DD MM YY',
  'D M YY',
  'D MM YYYY',
  'DD M YYYY',
  'D MM YY',
  'DD M YY',
  'DD MMM YYYY',
  'D MMM YYYY',
  'DD MMM YY',
  'D MMM YY',
  'DD MMMM YYYY',
  'D MMMM YYYY',
  'YYYY MM DD',
  'MMMM DD YYYY',
  'MMMM D YYYY',
  'MMMM DD YY',
  'MMMM D YY',
  'MMM DD YYYY',
  'MMM D YYYY',
  'MMM DD YY',
  'MMM D YY'
];

/**
 * parse a string value as date using moment
 */
export function dateParser(dateStr: string) {
  const normalizedDateStr = dateStr.toUpperCase().trim(); // Standardize to uppercase

  const fullMonthRegex =
    /JANVIER|FÉVRIER|MARS|AVRIL|MAI|JUIN|JUILLET|AOÛT|SEPTEMBRE|OCTOBRE|NOVEMBRE|DÉCEMBRE/;

  const abbreviatedMonthRegex =
    /JANV|FÉVR|MARS|AVR|MAI|JUIN|JUIL|AOÛT|SEPT|OCT|NOV|DÉC/;

  let locale: 'fr' | 'en' = 'en';
  
  if (fullMonthRegex.test(normalizedDateStr) || abbreviatedMonthRegex.test(normalizedDateStr)) {
    locale = 'fr';
  }

  moment.locale(locale);

  // Parse the date
  const parsedDate = moment(normalizedDateStr, formats, true);
  return parsedDate.isValid() ? parsedDate.toDate() : null;
}
